import { template as template_f87af576d0a6415ba351dbd52d58d316 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { longDate, relativeAge } from "discourse/lib/formatter";
export default class RelativeDate extends Component {
    get datetime() {
        return new Date(this.args.date);
    }
    get title() {
        return longDate(this.datetime);
    }
    get time() {
        return this.datetime.getTime();
    }
    static{
        template_f87af576d0a6415ba351dbd52d58d316(`
    <span
      class="relative-date"
      title={{this.title}}
      data-time={{this.time}}
      data-format="tiny"
    >
      {{relativeAge this.datetime}}
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
