import { template as template_18fb0640b9f447dda131c81d2efc2e7b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PasskeyLoginButton = template_18fb0640b9f447dda131c81d2efc2e7b(`
  <DButton
    @action={{@passkeyLogin}}
    @icon="user"
    @label="login.passkey.name"
    class="btn-social passkey-login-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PasskeyLoginButton;
