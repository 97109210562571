import { template as template_0a5a1e724df54f688e734d30d505967c } from "@ember/template-compiler";
const FKText = template_0a5a1e724df54f688e734d30d505967c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
