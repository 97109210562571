import { template as template_da4ac13a47a54a628fbaf5d4c9bb4b36 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
import { i18n } from "discourse-i18n";
const PrivateMessageGlyph = template_da4ac13a47a54a628fbaf5d4c9bb4b36(`
  {{#if @shouldShow}}
    {{#if @href}}
      <a href={{@href}} title={{i18n @title}} aria-label={{i18n @ariaLabel}}>
        <span class="private-message-glyph-wrapper">
          {{icon "envelope" class="private-message-glyph"}}
        </span>
      </a>
    {{~else}}
      <span class="private-message-glyph-wrapper">
        {{icon "envelope" class="private-message-glyph"}}
      </span>
    {{~/if}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PrivateMessageGlyph;
